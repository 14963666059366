<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flexspabet"
				@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>

				<div class="d-flex">
					<el-form-item label="类型:" class="w-170" label-width="77px">
						<el-select v-model="table.where.circle_type" placeholder="请选择" clearable class="ele-fluid">
							<el-option label="线圈" value="1" />
							<el-option label="头条" value="2" />
							<el-option label="景点" value="3" />
						</el-select>
					</el-form-item>
					<el-form-item label="省:" label-width="35px" class="w-170">
						<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
							placeholder='请选择省' clearable>
							<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="市:" label-width="35px" class="w-150">
						<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)"
							placeholder='请选择市' clearable>
							<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="县/区:" label-width="62px" class="w-200">
						<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
							<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<div class="ml-20">
						<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
							class="ele-btn-icon">搜索
						</el-button>
						<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
					</div>
				</div>
			</el-form>


			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" fit stripe
				show-header>
				<template slot-scope="{index}">
					<el-table-column type="index" :index="index" label="编号" width="60" align="center"
						show-overflow-tooltip />
					<el-table-column prop="circle_type" label="类型" show-overflow-tooltip min-width="80px" />
					<el-table-column prop="content" label="反馈内容" show-overflow-tooltip min-width="250px" />
					<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="150px" />
					<el-table-column label="添加时间" show-overflow-tooltip min-width="150px">
						<template slot-scope="{row}">{{ row.add_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="status" label="处理状态" show-overflow-tooltip min-width="120px" >
						<template slot-scope="scope">
							<span>{{scope.row.status==1?'审核通过':(scope.row.status==2?'未审核':'已拒绝')}}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="handlecheck(row.id,row.set_area)" icon="el-icon-view" type="primary"
								:underline="false" v-if="permission.includes('sys:headline:edit')">查看</el-link>
							<el-link @click="remove(row)" icon="el-icon-delete" type="danger" :underline="false"
								v-if="permission.includes('sys:headline:delete')">删除</el-link>
							<el-dropdown @command="command => dropClick(command,row)" v-if="row.status == 2">
								<span class="el-dropdown-link" style="margin-left: 10px;">
									<el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-s-check"
										v-if="permission.includes('sys:driver:check_list')" command="through">通过
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-delete-solid"
										v-if="permission.includes('sys:driver:delete')" command="refused">拒绝
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 查看详情 -->
		<el-dialog v-dialogDrag title="查看详情" :visible.sync="dialogTableVisibleCheck" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="checkForm" label-width="100px">
					<el-form-item label="类型：">
						{{checkForm.circle_type == 1 ? '线圈':(checkForm.circle_type == 2 ? '头条':(checkForm.circle_type == 3 ? '头条':'景点'))}}
					</el-form-item>
					<el-form-item label="标题：">
						{{checkForm.title}}
					</el-form-item>
					<el-form-item label="反馈内容：">
						{{checkForm.content}}
					</el-form-item>
					<el-form-item label="地区：">
						{{checkForm.address}}
					</el-form-item>
					<el-form-item label="图片：">
						<el-image v-for="(option, index) in checkForm.images" :key="index"
						style="width:70px;height:120px;margin-right:20px;" :src="option" fit="cover" :preview-src-list="checkForm.images"></el-image>
					</el-form-item>
				</el-form>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
	// import TinymceEditor from '@/components/TinymceEditor'
	import {
		mapGetters
	} from "vuex";
	// import uploadImage from '@/components/uploadImage';
	export default {
		name: "SysHeadline",
		// components: {TinymceEditor,uploadImage},
		data() {
			return {
				table: {
					url: '/headline/feedback_list',
					where: {}
				}, // 表格配置
				provArr: [],
				cityArr: [],
				districtArr: [],
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {}, // 表单数据
				editRules: {}, // 表单验证规则
				checkForm: {},
				dialogTableVisibleCheck: false
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
		},
		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {},
		methods: {
			editContent() {
				return {
					menubar: false,
					// file_picker_callback: this.file_picker_callback,
					skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' :
						'/tinymce/skins/ui/oxide',
					content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' :
						'/tinymce/skins/content/default/content.css',
				};
			},
			/**
			 *查看详情
			 **/
			handlecheck(id, address) {
				this.$http.post('/headline/feedback_info', {
					id: id
				}).then(res => {
					if (res.data.code === 0) {
						this.dialogTableVisibleCheck = true
						this.checkForm = res.data.data
						this.checkForm.images = res.data.data.country_cricle.images
						this.checkForm.title = res.data.data.country_cricle.title
						this.checkForm.address = address
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			},
			dropClick(command, row) {
				if (command === 'through') { //通过
					this.handleThrough(row)
				} else if (command === 'refused') { //拒绝
					this.handleRefused(row)
				}
			},
			//通过
			handleThrough(row) {
				this.$confirm('确认通过审核吗？', "提示").then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/headline/update_status', {
						id: row.id,
						status: 1
					}).then(res => {
						loading.close()
						if (res.data.code == 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload()
						} else {
							this.$message.error(res.data.msg)
						}
					}).catch(e => {
						loading.close()
						this.$message.error(e.message)
					})
				})
			},
			//拒绝
			handleRefused(row) {
				this.$confirm('确认驳回该审核吗？', "提示").then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/headline/update_status', {
						id: row.id,
						status: 3
					}).then(res => {
						loading.close()
						if (res.data.code == 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload()
						} else {
							this.$message.error(res.data.msg)
						}
					}).catch(e => {
						loading.close()
						this.$message.error(e.message)
					})
				})
			},
			/**
			 * 刪除
			 */
			remove(row) {
				// 单个删除
				this.$confirm('确定要删除吗?', '提示', {
					type: 'warning'
				}).then(() => {
					// const loading = this.$loading({lock: true});
					this.$http.post('/headline/circleFeedbackDelete', {
						id: row.id
					}).then(res => {
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						this.$message.error(e.message);
					});
				})

			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			/**
			 *选择省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cid = ''
					this.editForm.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aid = ''
				})
				}else{
					this.editForm.pid = ''
					this.editForm.aid = ''
				}
			},
		}
	}
</script>

<style scoped>
	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}

	/deep/.el-dialog {
		width: 40%;
	}
</style>
